import { Link } from "gatsby"
import React from "react"
import useRoutePath from "../../../hooks/useRoutePath"
import { slugify } from "../../../utils"
import Button from "../../atoms/Button"
import type { HarviaBackgroundColor } from "../../layouts/BgColor"
import BackgroundMedia from "../BackgroundMedia"
import MediaTextBlock, { MediaTextBlockLayout } from "./MediaTextBlock"
//import * as styles from "./CtaToPageBlock.module.scss"

export type CtaToPageBlockProps = Queries.DatoSharedCtaToPageFragment & {
  layout: MediaTextBlockLayout
  backgroundColor?: HarviaBackgroundColor
}

const CtaToPageBlock = (props: CtaToPageBlockProps) => {
  // console.debug('Rendering CtaToPageBlock', props)
  const getRoutePath = useRoutePath()
  const routePath = getRoutePath(props.route?.originalId)
  return (
    <MediaTextBlock
      id={slugify(props.title)}
      layout={props.layout}
      contentBackground={props.backgroundColor}
      media={props.image && <BackgroundMedia {...props.image} />}
      content={
        <>
          <h3>{props.title}</h3>
          <p style={{ flexGrow: 1 }}>{props.text}</p>
          {routePath && (
            <Link to={routePath + (props.urlParams || "")}>
              <Button label={props.linkText} tabIndex={-1} />
            </Link>
          )}
        </>
      }
    />
  )
}

export default CtaToPageBlock
