import { graphql } from "gatsby"
import React from "react"
import useArticlePath from "../../../hooks/useArticlePath"
import SubHeading from "../../atoms/SubHeading"
import ArticleTileLayout from "../../layouts/ArticleTileLayout"
import BackgroundMedia from "../../molecules/BackgroundMedia"
import ArticleBlock from "../../molecules/tileable-blocks/ArticleBlock"
import CtaToPageBlock from "../../molecules/tileable-blocks/CtaToPageBlock"

const DatoPickedArticles = (props: Queries.DatoPickedArticlesFragment) => {
  //console.log("Rendering DatoPickedArticles", props)
  const getArticlePath = useArticlePath()

  const pageBlock = props.sharedCtaToPage && (
    <CtaToPageBlock key={"page"} layout="TextLeft" backgroundColor="Transparent" {...props.sharedCtaToPage} />
  )

  const articleTiles: JSX.Element[] = []

  props.articles?.forEach(article => {
    if (!article) return

    const url = getArticlePath(article.originalId)

    // onko artikkeli saatavilla tällä sivustolla
    if (!url) return

    articleTiles.push(
      <ArticleBlock
        key={article.originalId}
        category={article.articleCategory?.name ?? undefined}
        title={article.title ?? undefined}
        excerpt={article.excerpt ?? undefined}
        image={<BackgroundMedia {...article.mainImage} />}
        url={url}
      />
    )
  })

  if (pageBlock) {
    articleTiles.unshift(pageBlock)
  }

  return (
    <section>
      <SubHeading text={props.title} />
      <ArticleTileLayout>{articleTiles}</ArticleTileLayout>
    </section>
  )
}

export default DatoPickedArticles

export const query = graphql`
  fragment DatoPickedArticles on DatoCmsPickedArticleSet {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title

    sharedCtaToPage {
      image {
        ...DatoImageDefault
      }
      title
      text
      linkText
      route {
        originalId
      }
    }

    articles {
      originalId
      slug
      mainImage {
        ...DatoImageDefault
      }
      title
      excerpt
      articleCategory {
        name
        slug
      }
      articleTags {
        label
      }
    }
  }
`
