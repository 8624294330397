import React from "react"
import * as styles from "./ArticleTileLayout.module.scss"
import TileLayout from "./TileLayout"

export type ArticleTileLayoutProps = {
  children: React.ReactNode
}

const ArticleTileLayout = (props: ArticleTileLayoutProps) => {
  //console.log("Rendering ArticleTileLayout", props)

  const articles = React.Children.map(props.children, child => child && <div className={styles.article}>{child}</div>)

  if (!articles) return null

  const first = articles[0]
  const secondTwo = articles.slice(1, 3)
  const rest = articles.slice(3)

  return (
    <div className={`${styles.articles} ${styles[`count${articles.length}`]}`}>
      <div className={styles.first}>{first}</div>
      {!!secondTwo.length && <div className={styles.secondTwo}>{secondTwo}</div>}
      {!!rest.length && (
        <div className={styles.rest}>
          <TileLayout>{rest}</TileLayout>
        </div>
      )}
    </div>
  )
}

export default ArticleTileLayout
