import React, { ReactNode } from "react"
import HighlightBlock from "./HighlightBlock"
//import * as styles from "./ArticleBlock.module.scss"

type ArticleBlockProps = {
  image: ReactNode
  title?: string
  excerpt?: string
  category?: string
  url: string
}

const ArticleBlock = (props: ArticleBlockProps) => {
  //console.log('Rendering ArticleBlock', props)

  //if (!props.url) return null

  return (
    <HighlightBlock
      linkTo={props.url}
      layout="Tile"
      contentPosition="Bottom"
      title={props.title}
      titleAs="h3"
      categoryTitle={props.category}
      media={props.image}
      content={!!props.excerpt && <p>{props.excerpt}</p>}
    />
  )
}

export default ArticleBlock
